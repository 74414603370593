.blog-post {
    font-weight: normal;
    font-family: 'Montserrat';
    font-size: 1rem;

    p {
        margin-bottom: 1rem;
    }

    h1 {
        margin-bottom: 1rem;
        font-weight: bold;
    }

    a {
        text-decoration: underline;
        color: #0000EE;
    }
}

