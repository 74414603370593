@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;800&family=Oswald:wght@200;300;400;500;600;700&display=swap');

// @font-face {
//         font-family: 'oswald';
//         src: url('https://fonts.googleapis.com/css2?family=Oswald&display=swap');
//         font-weight: normal;
//         font-style: normal;
// }

// @font-face {
//         font-family: 'oswald';
//         src: url('fonts/Oswald-SemiBold.ttf');
//         font-weight: 500;
//         font-style: normal;
// }

// @font-face {
//         font-family: 'oswald';
//         src: url('fonts/Oswald-Bold.ttf');
//         font-weight: 800;
//         font-style: normal;
// }

// @font-face {
//         font-family: 'montserrat';
//         src: url('fonts/Montserrat-Regular.ttf');
//         font-weight: normal;
//         font-style: normal;
// }

// @font-face {
//         font-family: 'montserrat';
//         src: url('fonts/Montserrat-SemiBold.ttf');
//         font-weight: 500;
//         font-style: normal;
// }

// @font-face {
//         font-family: 'montserrat';
//         src: url('fonts/Montserrat-Bold.ttf');
//         font-weight: 800;
//         font-style: normal;
// }

div, h2.chakra-heading {
        font-family: 'oswald';
}