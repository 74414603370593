@import '../../breakpoints.scss';

.swiper.productPageSwiper {
    width: 50%;
    float: left;

    @include breakpoint-small {
        width: 100%;
        float: none;
        margin: 0;
    }

    .swiper-slide img {
        width: 100%;
    }
}

.carousel, .carousel.carousel-slider {
    overflow: visible;
}

.carousel .control-arrow, .carousel.carousel-slider .control-arrow {
    background: #000;
    width: 52px;
    height: 52px;
    padding: 0;
    outline: none;
    color: transparent;
    font-size: 0px;
    line-height: 0;
    position: absolute;
    top: calc(50% - 52px);
    display: block;
    visibility: visible;
    cursor: pointer;
    z-index: 1;
    opacity: .5;
    box-shadow: 2px 2px 4px grey;

    @include breakpoint-small {
        width: 32px;
        height: 32px;
        top: calc(50% - 32px);
        border-width: 2px;
    }
}

.carousel .control-prev.control-arrow {
    left: -20px;
    padding-left: 5px;

    @include breakpoint-small {
        left: 0;
    }

    &:before {
        border-top: 2px solid white;
        border-right: 2px solid white;
        border-left: none;
        border-bottom: none;
        width: 12px;
        height: 12px;
        transform: rotate(227deg);
    }
}

.carousel .control-next.control-arrow {
    right: -20px;
    padding-right: 5px;

    @include breakpoint-small {
        right: 0;
    }

    &:before {
        border-top: 2px solid white;
        border-right: 2px solid white;
        border-left: none;
        border-bottom: none;
        width: 12px;
        height: 12px;
        transform: rotate(45deg);
    }
}

.iiz__hint {
    border: 3px solid #f2f3f7;
    background-color: #000;
    opacity: .5;
    // border-radius: 4px;
    // box-shadow: 2px 2px 4px grey;

    @include breakpoint-small {
        border-width: 0;
        width: 32px;
        height: 32px;
    }

    &::before {
        background-image: url('./zoom-in.svg');
    }
}

.accessory-description-link {
    display: flex;
    flex-direction: column;
    align-items: center;
}